import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Hero from '../components/Hero'
import Entries from '../components/Entries'
import RenderContent from '../components/RenderContent'
// import Section from '../components/Section'
import Article, { Title, Preamble } from '../components/Article'

import imageHelper from '../helpers/image-helper'

export default function ListTemplate ({ data }) {
  if (!data.ievv) {
    return <p>Something went wrong...</p>
  }

  const { title = null, preamble, content, heroImage, pageId } = data.ievv

  const pages = data.pages.edges
    .map(({ node }) => ({
      to: node.path,
      title: node.title || null,
      description: node.preamble,
      id: node.parentpage_id,
    }))
    .filter(({ id }) => id === pageId)

  const getImage = imageHelper(data)
  const hero = heroImage ? getImage(heroImage) : {}
  const hasContent = content && content.length > 0

  return (
    <>
      {heroImage && <Hero {...hero} title={title} preamble={preamble} />}

      {!heroImage && (
        <Article>
          <Title level="1">{title}</Title>
          {preamble && <Preamble>{preamble}</Preamble>}
        </Article>
      )}

      <Entries promoted={!hasContent} items={pages} spaceless={hasContent} />

      {hasContent && (
        <Article>
          <RenderContent data={data} />
        </Article>
      )}
    </>
  )
}

ListTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

ListTemplate.defaultProps = {
  darkHeader: true,
}

export const query = graphql`
  query($pageId: String!) {
    ievv(id: { eq: $pageId }) {
      ...ArticleData
    }
    ...GetImages
    pages: allIevv(sort: { fields: [order], order: [ASC] }) {
      edges {
        node {
          title
          preamble
          path
          parentpage_id
        }
      }
    }
  }
`
